<template>
<div>
  <div class="about">
    <!-- <el-menu default-active="5" class="el-menu-demo" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
      <el-menu-item index="0"  @click="handleSelect(0)" >查图片</el-menu-item>
      <el-menu-item index="1"  @click="handleSelect(1)" >标注框</el-menu-item>
      <el-menu-item index="2"  @click="handleSelect(2)" >查链接</el-menu-item>
      <el-menu-item index="3"  @click="handleSelect(3)" >查网站</el-menu-item>
      <el-menu-item index="4"  @click="handleSelect(4)" >看用户</el-menu-item>
      <el-menu-item index="5"  @click="handleSelect(5)">我 的</el-menu-item>
    </el-menu> -->
    <h2>首次登录请先联系管理员索要权限</h2>
    <!-- <el-button type="success" @click.stop="go_to_sw">筛选成堆图片</el-button> -->
    <el-button  type="info" @click="login_out">退出账号</el-button>
  </div>
</div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import router from '@/router/index.js'

export default {
  name: 'Mine',
  mounted () {
  },
  components: {
  },
  data () {
    return {
      loading: true,
      niming: store.state.niming,
      base_img: store.state.base_img
    }
  },
  methods: {
    get_index () {
    },
    login_out (e) {
      axios.post('/login_out/', {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          localStorage.removeItem('token')
          this.niming = true
          store.commit('is_niming')
          Utils.alert('已退出')
          location.href = store.state.base_url
        })
    },
    go_to_sw () {
      router.push({ name: 'Img_only_a', query: {} })
    },
    handleSelect (e) {
      if (e === 0) {
        router.push({ name: 'Img_index', query: {} })
      } else if (e === 1) {
        router.push({ name: 'Shape_index', query: {} })
      } else if (e === 2) {
        router.push({ name: 'Link_index', query: {} })
      } else if (e === 3) {
        router.push({ name: 'Site_index', query: {} })
      } else if (e === 4) {
        router.push({ name: 'User_index', query: {} })
      } else if (e === 5) {
        router.push({ name: 'Mine', query: {} })
      }
    }
  }
}
</script>
<style scoped>

</style>
